<template>
    <div>
        <section class="pt-5 pb-0 product breadcrumbsSection">
            <div class="container">
                <ol class="breadcrumbs">
                    <li><a href="/#/Home" class="router-link-active">{{this.$t('Pathyem.Home')}}</a></li>
                    <li>{{this.$t('Pathyem.chanpin')}}</li>
                </ol>
            </div>
        </section>
        <section class="pt-4 pt-lg-0">
            <div class="container">
                <div>
                    <h1 class="d-none">All products</h1>
                    <div class="masonry-filter-container d-flex align-items-center"><span>{{this.$t('News.Classification')}}</span>
                        <div class="masonry-filter-holder">
                               <el-select v-model="value"  @change="select_menus" placeholder="this.$t('News.Choice')">
                                    <el-option
                                        v-for="item in Menuns"
                                        :key="item.Id"
                                        :label="item.Menus"
                                        :value="item.Id">
                                    </el-option>
                                </el-select>
                        </div>
                    </div>
                    <h3></h3>
                    <hr>
                    <div  class="masonry__container row masonry--active">
                        <div data-masonry-filter="item.category_slug" v-for="(itme, index) in Product" :key="index" class="masonry__item col-6 col-md-4">
                            <div class="product">
                                <a :href="`#/Automotive/${itme.Id}`" class="">
                                    <img :alt="itme.Title" :src="`${$FilesImg}${itme.Imgsrc}`">
                                </a>
                                <a :href="`#/Automotive/${itme.Id}`" class="">
                                    <div>
                                        <h2 class="h5" v-html="itme.Title"></h2>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="pagination">
                    <!---->
                    <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="queryInfo.pagenum"
                        :page-sizes="[12, 15, 21, 30]"
                        :page-size="queryInfo.pagesize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="total">
                    </el-pagination>
                </div>
            </div>
        </section>
        <div>
           <Foorm></Foorm>
        </div>
    </div>
</template>
<script>
import Foorm from '../components/Foorm'
export default {
  data () {
    return {
      Path_text: '',
      value: '',
      Product: {
        Id: '',
        Cid: '',
        Title: '',
        Imgsrc: ''
      },
      Menuns: [],
      total: 0,
      queryInfo: {
        Id: this.$route.params.id,
        Pagenum: 1, // 当前的页数
        Pagesize: 12// 当前每页显示多少条
      }
    }
  },
  created () {
    this.Product_data()
    this.Product_Menuns()
  },
  mounted () {
  },
  components: {
    Foorm
  },
  updated () {

  },
  methods: {
    async Product_Menuns () {
      const { data: res } = await this.$http.get('Product_Menuns')
      if (res.status !== 200) return this.$message.error('数据异常')
      this.Menuns = res.response
      for (let index = 0; index < this.Menuns.length; index++) {
        if (this.queryInfo.Id === undefined) {
          this.value = this.$t('News.All_Categories')
        } else {
          if (this.Menuns[index].Id === parseInt(this.queryInfo.Id)) {
            this.value = this.Menuns[index].Menus
            return
          }
        }
      }
    },
    async Product_data () {
      const { data: res } = await this.$http.get('Product', { params: this.queryInfo })
      if (res.status !== 200) return this.$message.error('数据异常')
      this.Product = res.response.Product
      this.total = res.response.Total
    },
    // 监听pagesize的改变事件
    handleSizeChange (newSize) {
      this.queryInfo.Pagesize = newSize
      this.Product_data()
    },
    // 监听页面值的改变事件
    handleCurrentChange (newPage) {
      this.queryInfo.Pagenum = newPage
      this.Product_data()
    },
    async select_menus (data) {
      this.$router.push('/Product/' + data)
    }
  },
  watch: {
    '$route' (to, from) {
      this.queryInfo.Id = this.$route.params.id
      this.Product_data()
      this.Product_Menuns()
    }
  }
}
</script>
<style lang="less" scoped>
.pagination{
    margin-bottom: 10px;
}
footer,
    section {
        padding-top: 5.57142857em;
        padding-bottom: 5.57142857em;
    }
</style>
