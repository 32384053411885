import { render, staticRenderFns } from "./NyOpport.vue?vue&type=template&id=280fdbd1&scoped=true&"
import script from "./NyOpport.vue?vue&type=script&lang=js&"
export * from "./NyOpport.vue?vue&type=script&lang=js&"
import style0 from "./NyOpport.vue?vue&type=style&index=0&id=280fdbd1&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "280fdbd1",
  null
  
)

export default component.exports